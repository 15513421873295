import { Link } from "react-router-dom";

const Mainpage = () => {
  return (
    <div className="main-page-bg bg-center md:bg-[100%_0%] bg-cover bg-no-repeat min-h-screen">
      <div className="min-h-screen flex items-center justify-center">
        <div className="flex flex-col gap-16">
          <h1 className="font-press text-4xl lg:text-5xl xl:text-6xl text-white text-center">SoB MUTANTs</h1>
          <div className="isolate max-w-44 mx-auto">
            <Link to="/staking" className="px-6 pt-3 pb-3 inline-block bg-[#61FF00] rounded-3xl -z-[1] w-[172px] text-black font-press uppercase text-center text-3xl">
              Launch app
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mainpage;
