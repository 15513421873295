import { useState, useEffect, useContext } from "react";
import toast from "react-hot-toast";
import { ethers } from "ethers";
import { useChainId, useAccount, useReadContract, useWriteContract } from "wagmi";

import { Web3Context } from "../../context/Web3Context";

import {
  TEST_NET,
  SOBMUTANTS_ADDRESS_MAIN,
  SOBMUTANTS_ADDRESS_TEST,
  POINT_ADDRESS_MAIN,
  POINT_ADDRESS_TEST,
  GAME_ADDRESS_MAIN,
  GAME_ADDRESS_TEST,
  unrevealImg,
  revealImgHash,
} from "../../config";

import SobMutantAbi from "../../config/abis/SOBMUTANT_ABI.json";
import PointAbi from "../../config/abis/POINT_ABI.json";
import GameAbi from "../../config/abis/GAME_ABI.json";

import img_sobpoint from "../../assets/images/sobpoint.png";
import img_pending from "../../assets/images/pending.png";

import img_onjourney from "../../assets/images/onjourney.png";
import img_win from "../../assets/images/win.png";
import img_lose from "../../assets/images/lose.png";

const Staking = () => {
  const { callContractWait } = useContext(Web3Context);

  const chainId = useChainId();
  const { address: address } = useAccount();
  const decimal = 1e18;
  const GAME_ENTRY_PRICE = 10 * decimal;

  const { data: nftsOnWallet, refetch: refetchGetNFTsInWallet } = useReadContract({
    address: chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN,
    abi: GameAbi,
    functionName: "getTokensInWallet",
    args: [[chainId === TEST_NET ? SOBMUTANTS_ADDRESS_TEST : SOBMUTANTS_ADDRESS_MAIN], address],
    chainId: chainId,
  });

  const { data: winStatus, refetch: refetchWinStatus } = useReadContract({
    address: chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN,
    abi: GameAbi,
    functionName: "getWinningStatus",
    args: [nftsOnWallet?.map((item, index) => { return item.token_id })],
    chainId: chainId,
  });

  const { data: isApprovedSob, refetch: refetchApprove } = useReadContract({
    address: chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN,
    abi: GameAbi,
    functionName: "getApprovalStatus",
    args: [[chainId === TEST_NET ? SOBMUTANTS_ADDRESS_TEST : SOBMUTANTS_ADDRESS_MAIN], address],
    chainId: chainId,
  });

  const { data: nftsInPool, refetch: refetchGetNFTsInPool } = useReadContract({
    address: chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN,
    abi: GameAbi,
    functionName: "getStakingInfo",
    args: [address],
    chainId: chainId,
  });

  const { data: lockTime, refetch: refetchLockTime } = useReadContract({
    address: chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN,
    abi: GameAbi,
    functionName: "lockTime",
    args: [],
    chainId: chainId,
  });

  // const { data: reveal, refetch: refetchreveal } = useReadContract({
  //   address: chainId === TEST_NET ? SOBMUTANTS_ADDRESS_TEST : SOBMUTANTS_ADDRESS_MAIN,
  //   abi: SobMutantAbi,
  //   functionName: "reveal",
  //   args: [],
  //   chainId: chainId,
  // });
  const reveal = true;

  const { data: pointAllowance, refetch: refetchPointAllowance } = useReadContract({
    address: chainId === TEST_NET ? POINT_ADDRESS_TEST : POINT_ADDRESS_MAIN,
    abi: PointAbi,
    functionName: "allowance",
    args: [address, chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN],
    chainId: chainId,
  });

  const { data: sobBalance, refetch: refetchSobBalance } = useReadContract({
    address: chainId === TEST_NET ? POINT_ADDRESS_TEST : POINT_ADDRESS_MAIN,
    abi: PointAbi,
    functionName: "balanceOf",
    args: [address],
    chainId: chainId,
  });

  const [sobMutantsOnWallet, setSobMutantsOnWallet] = useState([]);

  const [sobMutantsInPool, setSobMutantsInPool] = useState([]);

  const [isPendingApproveSoBInPool, SetIsPendingApproveSoBInPool] = useState(false);

  const [isPendingApprovePointInPool, SetIsPendingApprovePointInPool] = useState(false);

  const [isPendingStakeInPool, SetIsPendingStakeInPool] = useState(false);

  useEffect(() => {
    const sobMutantsData = nftsOnWallet ? nftsOnWallet : [];

    const sobMutants = sobMutantsData
      ? sobMutantsData.map((item, index) => {
        return {
          id: Number(item.token_id),
          name: "SoB MUTANTs",
          image: `${revealImgHash}${Number(item.token_id)}.jpg`,
        };
      })
      : [];

    setSobMutantsOnWallet(sobMutants);
  }, [nftsOnWallet]);

  useEffect(() => {
    // @ts-ignore
    const items = nftsInPool
      ? nftsInPool[0].map((item, index) => {
        // @ts-ignore
        return {
          id: Number(item),
          name: "sobMutants",
          image: `${revealImgHash}${Number(item)}.jpg`,
          earned: Number(ethers.formatUnits(nftsInPool[1][index].toString(), 18)),
          staked: Number(nftsInPool[2][index])
        };
      })
      : [];
    setSobMutantsInPool(items);
  }, [nftsInPool]);

  async function handleStakeToPool(selectedNFT) {
    if (!checkSobApproval()) {
      SetIsPendingApproveSoBInPool(true);
      callContractWait(
        SobMutantAbi,
        chainId === TEST_NET ? SOBMUTANTS_ADDRESS_TEST : SOBMUTANTS_ADDRESS_MAIN,
        "setApprovalForAll",
        [chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN, true],
        address,
        (err) => {
          if (err) {
            console.log(err);
          }
          refetchApprove();
          SetIsPendingApproveSoBInPool(false);
        }
      );
      return;
    }

    if (!checkpointAllowance()) {
      SetIsPendingApprovePointInPool(true);
      callContractWait(
        PointAbi,
        chainId === TEST_NET ? POINT_ADDRESS_TEST : POINT_ADDRESS_MAIN,
        "approve",
        [chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN, GAME_ENTRY_PRICE * selectedNFTsOnWallet.length],
        address,
        (err) => {
          if (err) {
            console.log(err);
          }
          refetchPointAllowance();
          SetIsPendingApprovePointInPool(false);
        }
      );
      return;
    }

    if (selectedNFT.length <= 0) {
      toast.error("Select the NFTs that you are going to journey.");
      return;
    }

    if (Number(sobBalance) < GAME_ENTRY_PRICE * selectedNFT.length) {
      toast.error("Insufficient SoB Balance!");
      return;
    }

    SetIsPendingStakeInPool(true);
    callContractWait(
      GameAbi,
      chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN,
      "sendToJourney",
      [selectedNFT],
      address,
      (err) => {
        if (err) {
          console.log(err);
        }
        refetchGetNFTsInWallet();
        refetchGetNFTsInPool();
        SetIsPendingStakeInPool(false);
      }
    );
  }

  function checkSobApproval() {
    if (isApprovedSob) {
      return isApprovedSob[0].isApproval;
    }
    return false;
  }

  function checkpointAllowance() {
    if (Number(pointAllowance) >= GAME_ENTRY_PRICE * selectedNFTsOnWallet.length) {
      return true;
    }
    return false;
  }

  function getBtnTextStakeToPool() {
    if (isPendingApproveSoBInPool) return "Approving SoB Mutants...";

    if (isPendingApprovePointInPool) return "Approving SoB Token...";

    if (isPendingStakeInPool) return "Sending to Journey...";

    if (!checkSobApproval() || !checkpointAllowance()) return "Approve to Journey";

    return "Send to Journey";
  }

  const [selectedNFTsOnWallet, setSelectedNFTsOnWallet] = useState([]);

  const selectNFTsOnWallet = async (id) => {
    let selectedItems = selectedNFTsOnWallet.slice();
    selectedItems.push(Number(id));
    setSelectedNFTsOnWallet(selectedItems);
  };
  const unSelectNFTsOnWallet = async (id) => {
    let selectedItems = selectedNFTsOnWallet.slice();
    const index = selectedItems.indexOf(Number(id));
    selectedItems.splice(index, 1);
    setSelectedNFTsOnWallet(selectedItems);
  };

  useEffect(() => {
    setSelectedNFTsOnWallet([]);
  }, sobMutantsOnWallet);
  ///////////////////////////
  const [selectedNFTsOnPool, setSelectedNFTsOnPool] = useState([]);
  const [selectedNFT, setSelectedNFT] = useState();
  const [backTime, setBackTime] = useState("");

  const selectNFTsOnPool = async (id) => {
    let selectedItems = [];
    selectedItems.push(Number(id));
    setSelectedNFTsOnPool(selectedItems);

    const currentTime = Date.now() / 1000;
    sobMutantsInPool.map((item, index) => {
      if (item.id == id) {
        setSelectedNFT(item);

        const seconds = item.staked + Number(lockTime) < currentTime ? 0 : item.staked + Number(lockTime) - currentTime;
        const h = parseInt(seconds / 3600);
        const m = ((seconds % 3600) / 60).toFixed(0);
        setBackTime(`${h}h ${m}mins`);
        return;
      }
    });
  };

  const unSelectNFTsOnPool = async (id) => {
    let selectedItems = selectedNFTsOnPool.slice();
    const index = selectedItems.indexOf(Number(id));
    selectedItems.splice(index, 1);
    setSelectedNFTsOnPool(selectedItems);
  };

  useEffect(() => {
    setSelectedNFTsOnPool([]);
  }, sobMutantsInPool);

  return (
    <div className="min-h-screen">
      <div className="bmin-h-screen">
        <div className="max-w-[1680px] mx-auto lg:px-6 px-4 py-8">
          <div className="flex flex-col gap-5">
            <div className="grid lg:grid-cols-2 gap-5">
              <div className="h-full border-4 md:border-[10px] border-[#93FB5488] rounded-xl md:rounded-[28px] bg-center bg-no-repeat bg-cover">
                <div className="flex flex-col gap-8  bg-black/50 rounded-xl md:rounded-2xl h-full">
                  <div className="flex flex-wrap gap-y-4 items-center sm:gap-12 px-5 py-6 md:py-8 md:px-7">
                    <h4 className="font-press text-[#93FB54] text-4xl sm:text-5xl">SoB MUTANTs on Idle</h4>
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-12 min-h-[500px] max-h-[500px] md:max-h-[500px] overflow-y-auto pt-[20px] pr-[20px] sm:gap-12 px-5 md:px-7">
                    {sobMutantsOnWallet.map((item, index) => {
                      const selectState = selectedNFTsOnWallet.filter((item1) => Number(item1) === Number(item.id)).length > 0;
                      return (
                        <div key={index} className="relative">
                          {
                            winStatus && winStatus[index] == 2 ?
                              <img src={img_win} className="absolute w-14 h-14 -right-5 -top-5"></img>
                              :
                              winStatus && winStatus[index] == 1 ?
                                <img src={img_lose} className="absolute w-14 h-14 -right-5 -top-5"></img>
                                :
                                <></>
                          }
                          <div
                            className={`${selectState
                              ? "border-4 border-[#61FF0096] bg-[#61FF0096] text-[#000000]"
                              : "border-4 border-[#61FF0020] bg-[#61FF0020] text-[#ffffff]"
                              } overflow-hidden`}
                          >
                            <div className="px-2 py-2 md:py-2 md:px-2 min-[440px]-text-xs text-xl sm:text-xl lg:text-2xl font-press">
                              #{item.id}
                            </div>
                            {selectState ? (
                              <div onClick={() => unSelectNFTsOnWallet(item.id)}>
                                <img
                                  className="w-full"
                                  src={reveal ? item.image : unrevealImg}
                                  alt="nft"
                                  style={{ transform: "scale(0.98)" }}
                                />
                              </div>
                            ) : (
                              <div onClick={() => selectNFTsOnWallet(item.id)}>
                                <img className="w-full" src={reveal ? item.image : unrevealImg} alt="nft" />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex flex-wrap gap-y-4 items-center sm:gap-12 px-3 py-4 md:py-6 md:px-5 min-h-[170px] bg-[#2cf83944]">
                    <div className="flex-grow flex items-center flex-wrap gap-6">
                      <div className="flex-none">
                        <img src={img_sobpoint} alt="staking-profile-img" className="w-[64px] sm:w-[80px] lg:w-[96px]" />
                      </div>
                      <div className="w-[calc(100%-120px)]">
                        <h4 className="font-press text-[#93FB54] text-2xl sm:text-3xl">{`Send total of ${selectedNFTsOnWallet.length} SoBMutants to Journey with ${selectedNFTsOnWallet.length * 10} $SoBGamePoints`}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="h-full border-4 md:border-[10px] border-[#93FB5488] rounded-xl md:rounded-[28px] bg-center bg-no-repeat bg-cover">
                <div className="flex flex-col gap-8 bg-black/50 rounded-xl md:rounded-2xl h-full">
                  <div className="flex flex-wrap gap-y-4 items-center sm:gap-12 px-5 py-6 md:py-8 md:px-7">
                    <h4 className="font-press text-[#93FB54] text-4xl sm:text-5xl">SoB MUTANTs on Journey</h4>
                  </div>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-12 min-h-[500px] max-h-[500] md:max-h-[500px] overflow-y-auto pt-[20px] pr-[20px] sm:gap-12 px-5 md:px-7">
                    {sobMutantsInPool.map((item, index) => {
                      const selectState =
                        selectedNFTsOnPool.filter((item1) => Number(item1) === Number(item.id)).length > 0;
                      return (
                        <div key={index} className="relative">
                          <img src={img_onjourney} className="absolute w-14 h-14 -right-5 -top-5"></img>
                          <div
                            className={`${selectState
                              ? "border-4 border-[#F200CB96] bg-[#F200CB96] text-[#000000]"
                              : "border-4 border-[#00D1FF96] bg-[#00D1FF96] text-[#ffffff]"
                              } overflow-hidden`}
                          >
                            <div className="px-2 py-2 md:py-2 md:px-2 min-[440px]-text-xs text-xl sm:text-xl lg:text-2xl font-press">
                              #{item.id}
                            </div>
                            {selectState ? (
                              <div onClick={() => unSelectNFTsOnPool(item.id)}>
                                <img
                                  className="w-full"
                                  src={reveal ? item.image : unrevealImg}
                                  alt="nft"
                                  style={{ transform: "scale(0.98)" }}
                                />
                              </div>
                            ) : (
                              <div onClick={() => selectNFTsOnPool(item.id)}>
                                <img className="w-full" src={reveal ? item.image : unrevealImg} alt="nft" />
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex flex-wrap gap-y-4 items-center  sm:gap-12 px-3 py-4 md:py-6 md:px-5 min-h-[170px] bg-[#2cf83944]">
                    {
                      selectedNFTsOnPool.length > 0 ?
                        <div className="flex-grow flex items-center flex-wrap gap-6">
                          <div className="flex-none">
                            <img src={img_pending} alt="staking-profile-img" className="w-[64px] sm:w-[80px] lg:w-[96px]" />
                          </div>
                          <div className="w-[calc(100%-120px)]">
                            <h4 className="font-press text-[#93FB54] text-2xl sm:text-3xl">{`SoBMutant will come back on journey in ${backTime}.`}</h4>
                          </div>
                        </div>
                        :
                        <div className="flex-grow flex items-center flex-wrap gap-6">
                          <div className="flex-none">
                            <img src={img_sobpoint} alt="staking-profile-img" className="w-[64px] sm:w-[80px] lg:w-[96px]" />
                          </div>
                          <div className="w-[calc(100%-120px)]">
                            <h4 className="font-press text-[#93FB54] text-2xl sm:text-3xl">{`Total of ${sobMutantsInPool?.length} SoBMutants are on the Journey. To find the Juicy SoB Point`}</h4>
                          </div>
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative isolate text-center sm:text-center pt-10">
            <button
              type="button"
              className="min-w-[200px] text-center py-3.5 pb-2.5 bg-[#61FF00] rounded-3xl"
              onClick={() => handleStakeToPool(selectedNFTsOnWallet)}
            >
              <span className="uppercase text-black font-press text-3xl px-3">{getBtnTextStakeToPool()}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Staking;
