import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import Mainpage from "./pages/Mainpage";
import MainLayout from "./layout/MainLayout";

import Stake from "./pages/stake";
import "./App.css";

function App() {
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          // Define default options
          className: "topZIndex",
          duration: 2000,
          style: {
            background: "#fff",
            color: "black",
            fontWeight: "Bold",
          },

          // Default options for specific types
          success: {
            duration: 3000,
          },
        }}
      />
      <Routes>
        <Route path="/" element={<Mainpage />} />
        <Route path="/" element={<MainLayout />}>
          <Route index path="staking" element={<Stake />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
