import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

const MainLayout = () => {

  return (
    <div className="bg-[#1F1F1F]">
      <div className="min-h-screen game-bg">
        <Header />
        <main className="min-h-screen">
          <Outlet />
        </main>
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default MainLayout;
