export const MAIN_NET = 81457;
export const TEST_NET = 300;

export const POINT_ADDRESS_TEST = "0x24cD8e9d58F96aB3B99197EEaCf37bf82e7A73f7";
export const POINT_ADDRESS_MAIN = "0x182A765e3bd930B8c621C1fBB23704F311e168E5";

export const SOBMUTANTS_ADDRESS_TEST = "0xB19F3977e071b0CE27D09C0b15E4Ff09743A3ABE";
export const SOBMUTANTS_ADDRESS_MAIN = "0x47159d83d6Bc0b6bb1B11C6FbF69dE71aA9E55cb";

export const GAME_ADDRESS_TEST = "0xB03cc043ec20c94fCFBaa8bb33347E80A29a1D4d";
export const GAME_ADDRESS_MAIN = "0x17666ACB8AE0b7c6e419dc68Bd6c320fb9FD122d";

export const PAIR_ADDRESS_MAIN = "0xfaAc8e8f40C2f70B34103E01938e286c747110A8";
export const PYTH_ADDRESS_MAIN = "0xA2aa501b19aff244D90cc15a4Cf739D2725B5729";

export const unrevealImg = "https://raijins.mypinata.cloud/ipfs/QmTmoecmQ1LN4XPvSrj9mHmdk6vDkxyzjTdqqJAezsZF2U/unreveal.png";
export const revealImgHash = "https://zkmarkets.infura-ipfs.io/ipfs/QmYmWhwe5GUhQy29U19q812fQgK95qsTojXj8McdLvd4KH/SOBM%20JPEG/";