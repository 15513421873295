import { Link } from "react-router-dom";
import {
  CloseButtonIcon,
  HamburgerIcon,
} from "../assets/constants";
import { useEffect, useState } from "react";
import { useChainId, useAccount, useBalance, useReadContract } from "wagmi";
import { ethers } from "ethers";

import ConnectBtn from "./ConnectBtn";
import {
  TEST_NET,
  SOBMUTANTS_ADDRESS_MAIN,
  SOBMUTANTS_ADDRESS_TEST,
  POINT_ADDRESS_MAIN,
  POINT_ADDRESS_TEST,
  GAME_ADDRESS_MAIN,
  GAME_ADDRESS_TEST,
  PAIR_ADDRESS_MAIN,
  PYTH_ADDRESS_MAIN
} from "../config";
import SobMutantAbi from "../config/abis/SOBMUTANT_ABI.json";
import PointAbi from "../config/abis/POINT_ABI.json";
import GameAbi from "../config/abis/GAME_ABI.json";
import PairAbi from "../config/abis/PAIR_ABI.json";
import PythAbi from "../config/abis/PYTH_ABI.json";

import Logo from "../assets/images/logo.png"

const HEADER = {
  ZINDEX: 10
}

const Header = () => {

  const chainId = useChainId();
  const { address: address } = useAccount();

  const { data: mintCounter } = useReadContract({
    address: chainId === TEST_NET ? SOBMUTANTS_ADDRESS_TEST : SOBMUTANTS_ADDRESS_MAIN,
    abi: SobMutantAbi,
    functionName: "getMintCounter",
    args: [],
    chainId: chainId,
  });

  const { data: stakedCounter } = useReadContract({
    address: chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN,
    abi: GameAbi,
    functionName: "getTotalStakedCnt",
    args: [],
    chainId: chainId,
  });

  const { data: wardensBalanceData } = useReadContract({
    address: chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN,
    abi: GameAbi,
    functionName: "getBlastosaurzHoldingCnt",
    args: [address],
    chainId: chainId,
  });

  const { data: discountPercent } = useReadContract({
    address: chainId === TEST_NET ? GAME_ADDRESS_TEST : GAME_ADDRESS_MAIN,
    abi: GameAbi,
    functionName: "getDiscountVaporizePercent",
    args: [address],
    chainId: chainId,
  });

  const { data: _reserves } = useReadContract({
    address: PAIR_ADDRESS_MAIN,
    abi: PairAbi,
    functionName: "getReserves",
    args: [],
    chainId: chainId,
  });

  const { data: _ethPrice } = useReadContract({
    address: PYTH_ADDRESS_MAIN,
    abi: PythAbi,
    functionName: "getPrice",
    args: ["0xff61491a931112ddf1bd8147cd1b641375f79f5825126d665480874634fd0ace"],
    chainId: chainId,
  });


  const [MobNav, setMobNav] = useState(false);
  const [wardensBalance, setWardensBalance] = useState(0);
  const [decreaseRiskPercent, setDecreaseRiskPercent] = useState(0);
  const [pointPrice, setPointPrice] = useState(0.0);

  useEffect(() => {
    setWardensBalance(Number(wardensBalanceData));

    if (Number(discountPercent) > 10)
      setDecreaseRiskPercent(10);
    else
      setDecreaseRiskPercent(Number(discountPercent));
  }, [wardensBalanceData, discountPercent])

  useEffect(() => {
    if (_reserves && _ethPrice) {
      const ethReserved = Number(ethers.formatUnits(_reserves[0].toString(), 18));
      const enrgReserved = Number(ethers.formatUnits(_reserves[1].toString(), 18));
      const ethPrice = Number(ethers.formatUnits(_ethPrice.price.toString(), 8));
      const tokenPrice = ethReserved * ethPrice / enrgReserved;
      setPointPrice(tokenPrice);
    }
  }, [_reserves, _ethPrice])

  return (
    <header>
      <div>
        <div className="max-w-7xl mx-auto px-4 py-4">
          <div className="relative">
            <div className="flex items-center justify-between flex-wrap gap-y-3 gap-x-2">
              <ul className="hidden items-center gap-2.5 2xl:gap-4 flex-wrap sm:flex-nowrap md:flex">
                <Link to="/">
                  <img src={Logo} width={80} />
                </Link>
              </ul>
              <div className="hidden items-center gap-2.5 2xl:gap-8 flex-wrap gap-y-3 md:flex">
                <ul className="flex items-center gap-2.5 2xl:gap-4">
                  <li className={`relative isolate z-10`}>
                    <div className={`drawer drawer-end note-drawer`}>
                      <input id="note-drawer" type="checkbox" className="drawer-toggle" />
                      <div className="drawer-side">
                        <label htmlFor="note-drawer" aria-label="close sidebar" className="drawer-overlay"></label>
                        <ul className="menu p-4 w-80 min-h-full bg-[#ffffff20] backdrop-blur-md text-base-content pr-8">
                          <input id="note-drawer" type="checkbox" className="drawer-toggle" />
                          <label htmlFor="note-drawer" className="px-[12px] pt-3 inline-block cursor-pointer relative">
                            <div className="flex justify-center mb-5">
                              <CloseButtonIcon />
                            </div>
                          </label>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="relative isolate">
                    <ConnectBtn />
                  </li>
                </ul>
              </div>
              <Link to="/" className="md:hidden">
                <img src={Logo} alt="logo" width={80} />
              </Link>
              <div className='flex items-center gap-3 md:hidden'>
                <div className={`relative isolate z-10`}>
                  <div className="drawer drawer-end note-drawer-mobile">
                    <input id="note-drawer-mobile" type="checkbox" className="drawer-toggle" />
                    <div className="drawer-side">
                      <label htmlFor="note-drawer-mobile" aria-label="close sidebar" className="drawer-overlay"></label>
                      <ul className="menu p-4 w-80 min-h-full bg-[#ffffff20] max-md:bg-[#ffb7ee] md:backdrop-blur-md text-base-content">
                        <input id="note-drawer-mobile" type="checkbox" className="drawer-toggle" />
                        <label htmlFor="note-drawer-mobile" className="px-[12px] pt-3 inline-block cursor-pointer relative">
                          <div className="flex justify-center mb-5">
                            <CloseButtonIcon />
                          </div>
                        </label>
                      </ul>
                    </div>
                  </div>
                </div>
                <button type="button" onClick={() => setMobNav(!MobNav)}>
                  <HamburgerIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Nav  */}
        <div
          className={`${MobNav ? "translate-y-0" : "-translate-y-full"
            } fixed z-10 bg-[#180133] top-0 left-0 w-full min-h-screen lg:hidden block transition-all ease-in-out duration-300`}
        >
          <div className="flex items-center flex-col gap-12 px-4 py-10">
            <div>
              <button onClick={() => setMobNav(!MobNav)}>
                <CloseButtonIcon />
              </button>
            </div>
            <h3 className="text-center font-press text-white text-4xl sm:text-2xl md:text-3xl">SoB MUTANTs</h3>
            <div>
              <ul className="flex flex-col items-center gap-4 2xl:gap-4 flex-wrap sm:flex-nowrap">
                <ConnectBtn />
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
