import { ConnectButton } from "@rainbow-me/rainbowkit";

export default function ConnectBtn() {
  return (
    <ConnectButton.Custom>
    {({
      account,
      chain,
      openAccountModal,
      openChainModal,
      openConnectModal,
      authenticationStatus,
      mounted,
    }) => {
      const ready = mounted && authenticationStatus !== 'loading';
      const connected =
        ready &&
        account &&
        chain &&
        (!authenticationStatus ||
          authenticationStatus === 'authenticated');
          return (
            <>
              {
                !connected
                  ?
                  <button className="text-black font-press px-6 py-2.5 text-2xl uppercase  bg-[#61FF00] rounded-3xl" onClick={openConnectModal}>Connect wallet</button>
                  :
                chain.unsupported
                  ?
                  <button onClick={openChainModal} type="button" variant='warning' className="text-black font-press px-6 py-2.5 text-2xl uppercase  bg-[#61FF00] rounded-3xl" >
                    Wrong network
                  </button>
                  :
                  <div className='flex gap-3 isConnected flex-col md:flex-row items-center'>
                    <button
                      onClick={openChainModal}
                      type="button"
                      className="flex items-center justify-center text-black font-press bg-[#61FF00] rounded-3xl px-6 py-2.5 text-xl uppercase"
                      variant='primary'
                    >
                      {chain.hasIcon && (
                        <div
                          style={{
                            background: chain.iconBackground,
                            width: 12,
                            height: 12,
                            borderRadius: 999,
                            overflow: 'hidden',
                            marginRight: 4,
                          }}
                        >
                          {chain.iconUrl && (
                            <img
                              alt={chain.name ?? 'Chain icon'}
                              src={chain.iconUrl}
                              style={{ width: 12, height: 12 }}
                            />
                          )}
                        </div>
                      )}
                      {chain.name}
                    </button>
                    <button onClick={openAccountModal} type="button" variant='success' className="flex items-center justify-center text-black font-press  bg-[#61FF00] rounded-3xl px-6 py-2.5 text-xl uppercase">
                      {account.displayName}
                      {account.displayBalance
                        ? ` (${account.displayBalance})`
                        : ''}
                    </button>
                  </div>
              }
            </>
          )
    }}
  </ConnectButton.Custom>
  )
}